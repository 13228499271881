// lazyApi.js

// Proxy to the API module, which is loaded lazily to protect against unauthorized access by non logged-in users.

let api = null;

const loadApi = async () => {
  if (!api) {
    const module = await import('./api');
    api = module.default || module;
  }
  return api;
};

export const getUser = async () => {
  const apiModule = await loadApi();
  return apiModule.getUser();
};

export const getUserPersons = async () => {
  const apiModule = await loadApi();
  return apiModule.getUserPersons();
};

export const getUserMemoir = async () => {
  const apiModule = await loadApi();
  return apiModule.getUserMemoir();
};

export const updateMemoir = async (memoir) => {
  const apiModule = await loadApi();
  return apiModule.updateMemoir(memoir);
};

export const updateDraftMoment = async (moment) => {
  const apiModule = await loadApi();
  return apiModule.updateDraftMoment(moment);
}

export const clearEraText = async (eraValue) => {
  const apiModule = await loadApi();
  return apiModule.clearEraText(eraValue);
};

export const getUserEras = async () => {
  const apiModule = await loadApi();
  return apiModule.getUserEras();
};

export const getPerson = async (id) => {
  const apiModule = await loadApi();
  return apiModule.getPerson(id);
};

export const updateMoment = async (moment) => {
  const apiModule = await loadApi();
  return apiModule.updateMoment(moment);
};

export const generateEraText = async (eraValue, selectedMoments, style, temperature, author) => {
  const apiModule = await loadApi();
  return apiModule.generateEraText(eraValue, selectedMoments, style, temperature, author);
};

export const generateMemoirFromEraText = async (style, temperature, author) => {
  const apiModule = await loadApi();
  return apiModule.generateMemoirFromEraText(style, temperature, author);
};

export const updateEra = async (era) => {
  const apiModule = await loadApi();
  return apiModule.updateEra(era);
};

export const deleteMoment = async (moment) => {
  const apiModule = await loadApi();
  return apiModule.deleteMoment(moment);
};

export const getMoments = async (era) => {
  const apiModule = await loadApi();
  return apiModule.getMoments(era);
};