import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login, clearStatus, clearError } from './authSlice';
import { Button, TextField, Container, Typography, Box, Paper, Alert } from '@mui/material';
import { Link } from 'react-router-dom';  // Import Link from react-router-dom

const Login = () => {
  const [credentials, setCredentials] = useState({ email: '', password: '' });
  const dispatch = useDispatch();
  const { loading, status } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(clearStatus());
    dispatch(clearError());
  }, [dispatch]);

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(login(credentials));
  };

  return (
    <Container component="main" maxWidth="xs">
        <Paper elevation={3} sx={{ padding: 3, marginTop: 8 }}>
            <Typography component="h1" variant="h5" align="center">
                Login
            </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                <TextField
                    name="email"
                    label="Email"
                    value={credentials.email}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    name="password"
                    type="password"
                    label="Password"
                    value={credentials.password}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                />
                <Box sx={{ display: 'flex', gap: 2, marginTop: 2 }}>
                    <Button type="submit" variant="contained" disabled={loading} fullWidth>
                        {loading ? 'Logging in...' : 'Login'}
                    </Button>
                    <Link to="/register" style={{ textDecoration: 'none', flexGrow: 1 }}>
                        <Button variant="outlined" disabled={loading} fullWidth>
                            Register
                        </Button>
                    </Link>
                </Box>
                <Link to="/requestPasswordReset" style={{ textDecoration: 'none' }}>
                    <Button variant="text" sx={{ marginTop: 2 }}>
                        Forgot Password
                    </Button>
                </Link>
                {status && (
                    <Alert severity="error" sx={{ mt: 2 }}>
                        {status}
                    </Alert>
                )}
            </Box>
        </Paper>
    </Container>
);
};

export default Login;