import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { register, clearError, clearStatus } from './authSlice';
import { Button, TextField, Container, Typography, Box, Paper, Alert } from '@mui/material';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const Register = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearStatus());
    dispatch(clearError());
  }, [dispatch]);

  const [showPassword, setShowPassword] = useState(false);
  const [userData, setUserData] = useState({ name: '', email: '', password: '' });
  const { loading, status } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(register(userData));
  };

  const handleLoginRedirect = () => {
    navigate('/login');
  };

  const handleClickShowPassword = () => {
  setShowPassword(!showPassword);
};

  return (
    <Container component="main" maxWidth="xs">
        <Paper elevation={3} sx={{ padding: 3, marginTop: 8 }}>
            <Typography component="h1" variant="h5" align="center">
                Register
            </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                <TextField
                    name="name"
                    label="Name"
                    value={userData.name}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    name="email"
                    label="Email"
                    value={userData.email}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    name="password"
                    type="text"
                    label="Password"
                    value={userData.password}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                />
                <Box sx={{ display: 'flex', gap: 2, marginTop: 2 }}>
                    <Button type="submit" variant="contained" disabled={loading} fullWidth>
                        {loading ? 'Registering...' : 'Register'}
                    </Button>
                    <Button variant="outlined" onClick={handleLoginRedirect} fullWidth>
                        Login
                    </Button>
                </Box>
                {status && (
                    <Alert severity="info" sx={{ mt: 2 }}>
                        {status}
                    </Alert>
                )}
            </Box>
        </Paper>
    </Container>
);
};

export default Register;