// store.js
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import momentsReducer from './momentsSlice';
import sliderReducer from './sliderSlice';
import userReducer from './userSlice';


let store = configureStore({
  reducer: {
    user: userReducer,
    moments: momentsReducer,
    slider: sliderReducer,
    auth: authReducer,
  },
});

export default store;