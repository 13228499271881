// theme.js
import { createTheme } from '@mui/material/styles';

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#1976d2',
    },
    // Add other theme customizations here
  },
});

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    // Add other theme customizations here
  },
});

export const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#4a90e2',
    },
    secondary: {
      main: '#50c878',
    },
    background: {
      default: '#f0f4f8',
    },
    text: {
      primary: '#333333',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
});

