import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import * as api from './LazyApi';
import { useDispatch } from 'react-redux';
import { removeToken } from './authSlice';


export const getUser = createAsyncThunk(
  'user/getUser',
  async (thunkAPI) => {
    try {
      const response = await api.getUser();
      if (response === null) {
        removeToken();
        return thunkAPI.rejectWithValue('Failed to fetch user');
      }
      if (response.status !== 200) {
        return thunkAPI.rejectWithValue(`Failed to fetch user: ${response.status}`);
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(`Failed to fetch user: ${error.message}`);
    }
  }
);

export const removeUser = createAsyncThunk(
  'user/removeUser',
  async (thunkAPI) => {
    return true;
  }
);

// eraIndex is the index of the era to clear, different than eraValue which is the 
// associated float value
export const clearEraText = createAsyncThunk(
    'user/clearEraText',
        async (eraValue, thunkAPI) => {
        // get the user from the state
        const state = thunkAPI.getState();
        const response = await api.clearEraText(eraValue);
        return response.data;
    }
  );
  
  export const updateMemoir = createAsyncThunk(
    'user/updateMemoir',
    async (memoir, thunkAPI) => {
        const state = thunkAPI.getState();
        const response = await api.updateMemoir(memoir);
        return memoir;
        //return response.data;
    }
  );

  // returns the updated user
  export const generateEraText = createAsyncThunk(
    'generate/eraText',
    async (parameters,thunkAPI) => {
        const {eraValue, selectedMoments, style, temperature,author} = parameters;
        const response = await api.generateEraText(eraValue, selectedMoments,style, temperature, author);
        return response.data;
    }
  );

  // Generate memoir text
  export const generateMemoirFromEraText = createAsyncThunk(
    'generate/memoirText',
    async (parameters,thunkAPI) => {
        const {style, temperature,author} = parameters;
        const response = await api.generateMemoirFromEraText(style, temperature, author);
        return response.data;
    }
  );
  
  export const updateEra = createAsyncThunk(
    'user/eraUpdate',
    async (era, thunkAPI) => {
        // const state = thunkAPI.getState();
        await api.updateEra(era);
        return era;
    }
  );

  export const updateDraftMoment = createAsyncThunk(
    'user/updateDraftMoment',
    async (moment, thunkAPI) => {
        const response = await api.updateDraftMoment(moment);
        return response.data;
    }
  );

const userSlice = createSlice({
  name: 'user',
  initialState: { 
    //currentEraIndex: 0, // index of the current era
    currentEraIndex: sessionStorage.getItem('currentEra') || 0,
    user: null,
    draftMoment: null,
    savingDraft: false,
    isLoading: false,
    currentEra: null,
    error: false,
  },
  reducers: {
        setCurrentEraIndex: (state, action) => {
            state.currentEraIndex = action.payload;
            sessionStorage.setItem('currentEra', action.payload);
            state.currentEra = state.user.eras[state.currentEraIndex];
        }
  },
  extraReducers: (builder) => {
    // Moved extraReducers to correct location
    builder
        .addCase(getUser.pending, (state) => {
            state.isLoading = true;
            state.error = false;
            })
        .addCase(getUser.fulfilled, (state, action) => {
            state.user = action.payload;
            state.user.eras.sort((a, b) => a.value - b.value);
            state.currentEra = state.user.eras[state.currentEraIndex];
            state.isLoading = false;
        })
        .addCase(getUser.rejected, (state) => {
            state.isLoading = false;
            state.error = true;
            state.user = null;
            removeToken();
        })
        .addCase(removeUser.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        })
        .addCase(removeUser.fulfilled, (state) => {
            state.user = null;
            state.isLoading = false;
        })
        .addCase(updateMemoir.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        })
        .addCase(updateMemoir.fulfilled, (state, action) => {
           // state.currentEra = state.user.eras[state.currentEraIndex];
            // the memoir will update on the backend and will return the updated text
            state.user.memoir = action.payload;
            state.isLoading = false;
            state.error = false;
        })
        .addCase(updateMemoir.rejected, (state) => {
            state.isLoading = false;
            state.error = true;
        })
        .addCase(generateEraText.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        })
        .addCase(generateEraText.fulfilled, (state, action) => {
            // Whole user is returned, not just the era
            state.user = action.payload;
            // Sort the eras by value
            state.user.eras.sort((a, b) => a.value - b.value);
            // Will cause a re-render
            state.currentEra = state.user.eras[state.currentEraIndex];
            state.isLoading = false;
        })
        .addCase(generateEraText.rejected, (state) => {
            state.isLoading = false;
            state.error = true;
        })
        .addCase(updateEra.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        })
        // will return the era object, not the whole updated user
        .addCase(updateEra.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.user.eras[state.currentEraIndex] = action.payload;
            state.currentEra = action.payload;
            //state.currentEra = action.payload;
        })
        .addCase(updateEra.rejected, (state) => {
            state.isLoading = false;
            state.error = true;
        })
        .addCase(clearEraText.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        })
        .addCase(clearEraText.fulfilled, (state, action) => {
            state.error = false;
            state.isLoading = false;
        })
        .addCase(clearEraText.rejected, (state) => {
            state.isLoading = false;
            state.error = true;
        })
        .addCase(updateDraftMoment.pending, (state) => {
            state.isLoading = true;
            state.savingDraft = true;
            state.error = false;
        })
        .addCase(updateDraftMoment.fulfilled, (state, action) => {
            state.draftMoment = action.payload;
            state.isLoading = false;
            state.savingDraft = false;
        })
        .addCase(updateDraftMoment.rejected, (state) => {
            state.isLoading = false;
            state.savingDraft = false;
            state.error = true;
        })

    }
});

export const selectUser = state => state.user.user;
export const selectUserEras = state => state.user?.eras;
export const selectUserMemoir = state => state.user.user?.memoir;
export const selectUserError = state => state.user.error;
export const selectUserLoading = state => state.user.isLoading;
//export const selectUserEra = state => state.user.user?.eras[state.user.currentEraIndex]
export const selectCurrentEra = state => state.user.currentEra
export const selectUserEraIndex = state => state.user.currentEraIndex;
export const selectUserEraText = state => state.user.currentEra.era_text;
export const selectUserDeletedText = state => state.user.currentEra.deleted_text;
//export const { updateCurrentEra } = userSlice.actions;
export default userSlice.reducer;
export const { setCurrentEraIndex } = userSlice.actions;