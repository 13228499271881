import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './store';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {lightTheme, darkTheme, defaultTheme } from './theme';

// Add these imports for Roboto font
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// Suppress specific ResizeObserver errors, occurring when the splitter is moved too much
const suppressResizeObserverErrors = () => {
  const resizeObserverLoopErrRe = /^[^(ResizeObserver loop limit exceeded)]/;
  const originalConsoleError = console.error;
  console.error = (...args) => {
    if (resizeObserverLoopErrRe.test(args[0])) {
      // Ignore ResizeObserver loop limit exceeded error
    } else {
      originalConsoleError.apply(console, args);
    }
  };
};

// Patch the ResizeObserver to catch errors
const patchResizeObserver = () => {
  const originalResizeObserver = window.ResizeObserver;
  window.ResizeObserver = class PatchedResizeObserver extends originalResizeObserver {
    constructor(callback) {
      super((entries, observer) => {
        requestAnimationFrame(() => {
          callback(entries, observer);
        });
      });
    }
  };
};

// Apply both patches
suppressResizeObserverErrors();
patchResizeObserver();

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
 // TODO: remove StrictMode after debugging, causes multiple renders

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={defaultTheme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();