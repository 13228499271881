import axios from 'axios';
import { saveToken } from './authSlice';
// if production, use the production API, otherwise use localhost
const API_URL = process.env.REACT_APP_API_URL;
const isProduction = process.env.REACT_APP_ENV === 'production';

console.log(`Running in ${isProduction ? 'production' : 'development'} mode`);
console.log(`API URL: ${API_URL}`);
//const API_URL = 'http://localhost:8000/api/';
// const API_URL = 'https://lifewriter.ai/api/';

const USER_API_URL = API_URL + 'user';

// Create an axios instance with default headers
const api = axios.create({
  baseURL: USER_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export async function login(credentials) {
  try {
    const response = await axios.post(USER_API_URL + '/token', new URLSearchParams({
      username: credentials.email,
      password: credentials.password
    }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      timeout: 5000
    });
    const { access_token } = response.data;
    saveToken(access_token);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function register(userdata) {
  const response = await axios.post(USER_API_URL + '/register', new URLSearchParams({
    name: userdata.name,
    email: userdata.email,
    password: userdata.password
  }), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 5000
  });
  return response;
}

export async function checkPasswordResetCode(userdata) {
  const response = await axios.get(USER_API_URL + '/checkPasswordResetCode', {
    params: {
      user_id: userdata.user_id,
      code: userdata.code
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 5000
  });
  return response;
}

export async function requestPasswordReset(email) {
  const response = await axios.post(USER_API_URL + '/requestPasswordReset', new URLSearchParams({
    email: email
  }), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 5000
  });
  return response;
} 

export async function resetPassword(data) {
  const response = await axios.post(USER_API_URL + '/resetPassword', new URLSearchParams({
    user_id: data.user_id,
    password: data.password,
    code: data.code
  }), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 5000
  });
  return response;
}