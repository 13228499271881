import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { resetPassword, checkPasswordResetCode, clearStatus, clearError  } from './authSlice';
import { TextField, Container, Typography, Box, Paper, Alert } from '@mui/material';


const ResetPassword = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');
    const user_id = queryParams.get('user_id');
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();
    const [message, setMessage] = useState('');
    const handleLoginRedirect = () => {
        navigate('/login');
    };


    const checkResetCode = () => {
        if (!code || !user_id) {
            setMessage('Error: Invalid reset link.');
        }
        const data = { user_id, code };
        dispatch(checkPasswordResetCode(data))
        .then((response) => {
            if (response.payload) {
                setMessage(response.payload.message);
            } else {
                setMessage('Invalid reset link, please reset your password again.');
            }
            }
        )
        .catch((error) => {
            setMessage('Error: ' + error.message);
        });
    };


    useEffect(() => {
        setMessage('');
        dispatch(clearStatus());
        checkResetCode();
    }, [dispatch]);

    const handleResetPassword = () => {
        const data = { user_id, password, code };
        dispatch(resetPassword(data))
            .then((response) => {
                if (response.payload) {
                    setMessage(response.payload.message);
                } else {
                    setMessage('Something went wrong.');
                }
            })
            .catch((error) => {
                setMessage('Error: ' + error.message);
            });
    };

    return (
        <Container component="main" maxWidth="xs">
            <Paper elevation={3} sx={{ padding: 3, marginTop: 8 }}>
                <Typography component="h1" variant="h5" align="center">
                    Reset Password
                </Typography>
                <Box component="form" onSubmit={handleResetPassword} sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="password"
                        label="New Password"
                        type="password"
                        autoComplete="new-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={handleResetPassword}
                    >
                        Save New Password
                    </Button>
                    <Button
                        type="button"
                        fullWidth
                        variant="outlined"
                        color="secondary"
                        onClick={handleLoginRedirect}
                    >
                        Login
                    </Button>
                    {message && (
                        <Alert severity="info" sx={{ mt: 2 }}>
                            {message}
                        </Alert>
                    )}
                </Box>
            </Paper>
        </Container>
    );
};

export default ResetPassword;