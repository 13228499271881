import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { getMoments, updateMoment as updateMomentApi, deleteMoment as deleteMomentApi } from './LazyApi';

// Load selected moments from local storage
// selected moments is a map of moment id to selected state
const loadSelectedMoments = () => {
  const selectedMoments = localStorage.getItem('selectedMoments');
  return selectedMoments ? JSON.parse(selectedMoments) : {};
};

// lightweight era object for moment reference
const convertEra = (era) => {
  return { value: era.value, user_id: era.user_id, description: era.description };
}

export const fetchMoments = createAsyncThunk(
  'moments/fetch',
  async ({era}, thunkAPI) => {
    const response = await getMoments(era);
    return [era, response.data];
  }
);

export const updateMoment = createAsyncThunk(
  'moments/update',
  async (moment, thunkAPI) => {
    const state = thunkAPI.getState();
    const newEra = moment.era !== null ? moment.era : state.moments.currentEra;
    moment.era = convertEra(newEra);
    const response = await updateMomentApi(moment);
    return response.data;
  }
);

export const createMoment = createAsyncThunk(
  'moments/create',
  async (moment, thunkAPI) => {
    const state = thunkAPI.getState();
    moment.era = convertEra(state.moments.currentEra);
    moment.user_id = moment.era.user_id;
    const response = await updateMomentApi(moment);
    return response.data;
  }
);

export const deleteMoment = createAsyncThunk(
  'moments/delete',
  async (moment, thunkAPI) => {
    const response = await deleteMomentApi(moment);
    return response.data;
  }
);

const momentsSlice = createSlice({
  name: 'moments',
  initialState: { 
    eraMoments: [],
    currentEra: null,
    isLoading: false,
    error: false,
    emptyMoment: {
      id: null,
      title: '',
      when: '',
      location: '',
      description: '',
      era: null,
      momentSelected: true
    },
    selectedMoments: loadSelectedMoments()
  },
  reducers: {
    selectAllMoments: (state) => {
      state.eraMoments.forEach(moment => {
        state.selectedMoments[moment.id] = true;
      });
      localStorage.setItem('selectedMoments', JSON.stringify(state.selectedMoments));
    },
    toggleMomentSelection: (state, action) => {
      const moment = state.eraMoments.find(m => m.id === action.payload);
      if (moment) {
        // toggle the moment's selected state
        moment.momentSelected = !moment.momentSelected;
        // selected moments is a map of moment id to selected state
        state.selectedMoments[moment.id] = moment.momentSelected;
        // remember it in local storage
        localStorage.setItem('selectedMoments', JSON.stringify(state.selectedMoments));
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteMoment.fulfilled, (state, action) => {
        state.error = false;
        state.eraMoments = state.eraMoments.filter(moment => moment.id !== action.payload);
        delete state.selectedMoments[action.payload];
        localStorage.setItem('selectedMoments', JSON.stringify(state.selectedMoments));
      })
      .addCase(fetchMoments.pending, (state) => {
        state.isLoading = true;
        state.error = false;
      })
      .addCase(fetchMoments.fulfilled, (state, action) => {
        const era = action.payload[0];
        action.payload[1].sort((a, b) => a.when - b.when);
        state.eraMoments = action.payload[1];
        state.eraMoments.forEach(moment => {
          moment.momentSelected = state.selectedMoments[moment.id] || false;
        });
        state.currentEra = era;
        state.isLoading = false;
        state.error = false;
      })
      .addCase(fetchMoments.rejected, (state) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(createMoment.fulfilled, (state, action) => {
        state.error = false;
        const index = state.eraMoments.findIndex(moment => moment.id === null);
        if (index !== -1) {
          state.eraMoments[index] = action.payload;
        } else {
          state.eraMoments.unshift(action.payload);
        }
        state.emptyMoment = {
          id: null,
          title: '',
          when: '',
          location: '',
          description: '',
          era: null,
          momentSelected: false
        };
      });
  },
});

export const { toggleMomentSelection } = momentsSlice.actions;
export const selectEraMoments = state => state.moments.eraMoments;
export const selectEmptyMoment = state => state.moments.emptyMoment;
export const selectSelectedMoments = state => state.moments.selectedMoments;
export const { selectAllMoments } = momentsSlice.actions;
export default momentsSlice.reducer;