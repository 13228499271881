// sliderSlice.js
import { createSlice } from '@reduxjs/toolkit';

const sliderSlice = createSlice({
  name: 'slider',
  initialState: { 
    sliderIndex: 0 
    },
  reducers: {
    setSliderIndex: (state, action) => {
        state.sliderIndex = action.payload;
    },
  },
});

export const { setSliderIndex } = sliderSlice.actions;
export const selectSliderIndex = state => state.slider.sliderIndex;

export default sliderSlice.reducer;