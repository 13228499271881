import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { requestPasswordReset, clearStatus, clearError } from './authSlice';
import { Button, TextField, Container, Typography, Box, Paper, Alert } from '@mui/material';


const PasswordReset = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(clearStatus());
        dispatch(clearError());
    }, [dispatch]);

    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handlePasswordReset = () => {
        dispatch(requestPasswordReset(email))
            .then((response) => {
                if (response.payload) {
                    setMessage(response.payload.message);
                } else {
                    setMessage('Something went wrong.');
                }
            })
            .catch((error) => {
                setMessage('Error: ' + error.message);
            });
    };

    return (
        <Container component="main" maxWidth="xs">
            <Paper elevation={3} sx={{ padding: 3, marginTop: 8 }}>
                <Typography component="h1" variant="h5" align="center">
                    Password Reset
                </Typography>
                <Box component="form" onSubmit={handlePasswordReset} sx={{ mt: 1 }}>
                    <TextField
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={handlePasswordReset}
                    >
                        Send Reset Email
                    </Button>
                    {message && (
                        <Alert severity="info" sx={{ mt: 2 }}>
                            {message}
                        </Alert>
                    )}
                </Box>
            </Paper>
        </Container>
    );
};

export default PasswordReset;