/**
 * @file App.js
 * @description This file contains the main application component for the Lifewriter application.
 * @version 1.0.0
 * @date 2023-10-05
 * 
 * © 2023 Lifewriter. All rights reserved.
 */

import React, { useEffect, lazy, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import './App.css';

// Import slices and selectors
import { selectIsAuthenticated, logout } from './authSlice';
import { selectUser, selectCurrentEra, getUser } from './userSlice';

// Import components
import Login from './Login';
import Register from './Register';
import RequestPasswordReset from './RequestPasswordReset';
import ResetPassword from './ResetPassword';

// Lazy load the ProtectedApp component
const ProtectedApp = lazy(() => import('./ProtectedApp'));

// Main App component
function App() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const currentUser = useSelector(selectUser);
  const currentEra = useSelector(selectCurrentEra);

  // Force HTTPS in production
  useEffect(() => {
    if (window.location.protocol === 'http:' && process.env.NODE_ENV === 'production') {
      window.location.href = window.location.href.replace('http:', 'https:');
    }
  }, []);

  // Initial fetch of user data
  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getUser());
    }
  }, [isAuthenticated, dispatch]);

  return (
    <Router>
      <div className="app-container">
        <Routes>
          <Route 
            path="/login" 
            element={isAuthenticated ? <Navigate to="/" replace /> : <Login />} 
          />
          <Route 
            path="/register" 
            element={<Register />}
          />
          <Route 
            path="/requestPasswordReset" 
            element={<RequestPasswordReset />}
          />
           <Route 
            path="/resetPassword" 
            element={<ResetPassword />}
          />
          <Route 
            path="/*" 
            element={
              isAuthenticated ? (
                <Suspense fallback={<CircularProgress />}>
                  <ProtectedApp 
                    currentUser={currentUser}
                    currentEra={currentEra}
                    logout={() => dispatch(logout())}
                  />
                </Suspense>
              ) : (
                <Navigate to="/login" replace />
              )
            } 
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;