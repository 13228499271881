import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { register as registerApi, login as loginApi, requestPasswordReset as requestResetApi, resetPassword as resetPasswordApi, checkPasswordResetCode as checkPasswordResetCodeApi} from './LoginApi';
import { removeUser } from './userSlice';

// Other imports and code...

// Selector to get the authentication state
export const selectIsAuthenticated = (state) => state.auth.isAuthenticated;

// Retrieve the token from localStorage
export const getToken = () => {
  return localStorage.getItem('jwtToken');
};

// Other exports and code...
// Define the initial state
const initialState = {
  isAuthenticated: !!getToken(),
  loading: false,
  status: null,
  error: null,
};

// Save the token to localStorage
export const saveToken = (token) => {
  localStorage.setItem('jwtToken', token);
};

// Remove the token from localStorage
export const removeToken = () => {
  localStorage.removeItem('jwtToken');
};

// Clear error action
export const clearError = createAction('auth/clearError');

// Clear status action
export const clearStatus = createAction('auth/clearStatus');

export const register = createAsyncThunk(
  'auth/register',
  async (userData, { rejectWithValue }) => {
    try {
      const response = await registerApi(userData);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response.status === 400?
          {
          status: error.response.status,
          message: error.response.data.detail,
          }:{
          status: error.response.status,
          message: error.response.statusText,
      });

    }
  }
);

export const requestPasswordReset = createAsyncThunk(
  'auth/requestPasswordReset',
  async (email, { rejectWithValue }) => {
    try {
      const response = await requestResetApi(email);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response.status === 400?
          {
          status: error.response.status,
          message: error.response.data.detail,
          }:{
          status: error.response.status,
          message: error.response.statusText,
      });

    }
  }
);

export const checkPasswordResetCode = createAsyncThunk(
  'auth/checkPasswordResetCode',
  async (data, { rejectWithValue }) => {
    try {
      const response = await checkPasswordResetCodeApi(data);
      return (response.status === 200)?
        {
          status: response.status,
          message: "Enter your new password",
        }:
        {
          status: response.status,
          message: response.data,
        };
    } catch (error) {
      return rejectWithValue(
        error.response.status === 400?
          {
          status: error.response.status,
          message: error.response.data.detail,
          }:{
          status: error.response.status,
          message: error.response.statusText,
      });
   }
  }
);

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async (data, { rejectWithValue }) => {
    try {
      const response = await resetPasswordApi(data);
      return (response.status === 200)?
      {
        status: response.status,
        message: "Password reset successful, please login with your new password",
      }:
      {
        status: response.status,
        message: response.data,
      };
    } catch (error) {
      return rejectWithValue(
        error.response.status === 400?
          {
          status: error.response.status,
          message: error.response.data.detail,
          }:{
          status: error.response.status,
          message: error.response.statusText,
      });
    }
  }
);


export const login = createAsyncThunk(
  'auth/login',
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await loginApi(credentials);
      saveToken(response.access_token); // Save the token to localStorage
      return response.access_token;
    } catch (error) {
      return rejectWithValue(
        error.response.status === 400 || error.response.status === 401?
          {
          status: error.response.status,
          message: error.response.data.detail,
          }:{
          status: error.response.status,
          message: error.response.statusText,
      });

    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.isAuthenticated = false;
      removeToken();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(register.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.status = "Registering...";
      })
      .addCase(register.fulfilled, (state, action) => {
        state.loading = false;
        state.status = 'Registration successful!';
      })
      .addCase(register.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
        state.status = `${action.payload.message}: ${action.payload.status} `;
      })
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.status = "Logging in...";
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload;
        state.isAuthenticated = true;
        state.status = 'Login successful!';
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        removeToken(); // Remove token on login failure
        // call to remove user data
        removeUser();
        state.error = action.payload.message;
        state.status = `${action.payload.message}: ${action.payload.status} `;
      })
      .addCase(clearError, (state) => {
        state.error = null;
      })
      .addCase(clearStatus, (state) => {
        state.status = null;
      });
  },
});

// Export the logout action
export const { logout } = authSlice.actions;

export default authSlice.reducer;